import {
    googleAnalyticsTrackAddToCart,
    googleAnalyticsTrackBeginCheckout,
    googleAnalyticsTrackRemoveFromCart,
    googleAnalyticsTrackViewCart, googleAnalyticsTrackViewItem
} from './analytics-google';

export function trackAddToCart(cartItem) {
    googleAnalyticsTrackAddToCart(cartItem);
}

export function trackViewCart(cart) {
    googleAnalyticsTrackViewCart(cart);
}

export function trackRemoveFromCart(cartItem) {
    googleAnalyticsTrackRemoveFromCart(cartItem);
}

export function trackBeginCheckout(cart) {
    googleAnalyticsTrackBeginCheckout(cart);
}

export function trackViewItem(cartItem) {
    googleAnalyticsTrackViewItem(cartItem);
}
