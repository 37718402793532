import axios from './axios';
import { handleAjaxError } from './error-handler';
import { trackViewCart } from './analytics';

class CartObserver {
    constructor() {
        this.data = null;
        this.subscribers = [];
    }

    update(initiator, data = null) {
        if (data === null) {
            return axios.get('/_cart/cart/get').then((response) => {
                this.subscribers.forEach(subscriber => subscriber(response.data, initiator));
                this.data = response.data;
            }).catch(handleAjaxError);
        }

        return new Promise((resolve) => {
            this.subscribers.forEach(subscriber => subscriber(data, initiator));
            this.data = data;
            resolve();
        });
    }

    subscribe(callback) {
        this.subscribers.push(callback);
    }

    showCart() {
        document.body.classList.add('show-cart');
        trackViewCart(this.data);
    }
}

window.CartObserver = window.CartObserver || new CartObserver();

export default window.CartObserver;
