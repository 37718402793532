<template>
    <div class="cart-min__inside">
        <template v-if="loading">
            <div class="cart-min__loading">
                <div class="spinner-border text-primary"></div>
                <p>Cart Update</p>
            </div>
        </template>

        <!--  Services panel-->
        <div class="cart-min__services" v-if="services.length">
            <div class="cart-min__header cart-min__header--service">
                <div class="cart-min__header-title">Additional Services</div>
                <button @click="onCloseServicesPanel()" class="btn btn-one-icon"><i class="icon-x"></i></button>
            </div>

            <div class="cart-min__service" v-for="service in services">
                <div class="cart-min__service-inside">
                    <div class="cart-min__service-icon" v-if="service.icon" v-html="`<i data-icon='&#x${service.icon};'></i>`"></div>
                    <div class="cart-min__service-image" v-else-if="service.image">
                        <img :src="service.image" :alt="service.name">
                    </div>
                    <div>
                        <div class="cart-min__service-name">{{ service.name }}</div>
                        <div class="cart-min__service-price">
                            <div class="product-price product-price--cart-min-service">
                                <div class="product-price__value">{{ service.price }}</div>
                                <div class="product-price__text">(VAT incl.)</div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-min__service-buttons">
                        <div class="buttons-group buttons-group--cart">
                            <button class="cart-min__service-buttons-info btn btn-lighten btn-one-icon btn-one-icon--md rounded-circle" :content="service.teaser" v-tippy="{ maxWidth : 220 }" v-if="service.teaser">
                                <i class="icon icon-info"></i>
                            </button>

                            <button @click="onProductRemove(service, service.quantity)"
                                    class="btn btn-primary btn-one-icon btn-one-icon--md rounded-circle" v-if="service.inCart">
                                <i class="icon icon-x"></i>
                            </button>
                            <button @click="onProductAdd(service, 1)"
                                    class="btn btn-primary btn-one-icon btn-one-icon--md rounded-circle" v-else>
                                <i class="icon icon-shopping-bag"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Cart panel-->
        <div class="cart-min__content">

            <div class="cart-min__header">
                <div class="cart-min__header-title">Your Cart</div>
                <button @click="onCartClose()" class="btn btn-one-icon"><i class="icon-x"></i></button>
            </div>

            <div class="cart-min__content-main">
                <div class="cart-min__products">
                    <template v-if="items.length">
                        <div class="cart-min__product" v-for="product in items">
                            <div class="cart-min__product-row">
                                <div class="cart-min__product-name">
                                    {{ product.name }}
                                </div>
                                <div class="cart-min__product-remove">
                                    <button @click="onProductRemove(product, product.quantity)"
                                            class="btn-no-style">
                                        <i class="icon-x"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="cart-min__product-row">
                                <div>{{ product.unitPrice }}</div>
                                <template v-if="product.hasQuantity">
                                    <div><i class="icon-x"></i></div>
                                    <div class="input-quantity">
                                        <input :value="product.quantity" type="number" min="1"
                                               @change="onProductChangeQuantity(product, $event)" class="">
                                    </div>
                                </template>
                                <div class="ml-auto">
                                    <div class="product-price product-price--block product-price--md" v-tippy="{ placement: 'top', onShow: () => product.rules.length > 0}" :content="product.rules.join(', ')">
                                        <div class="product-price__value">
                                            {{ product.totalPrice }}
                                        </div>
                                        <div class="product-price__old" v-if="product.totalPrice !== product.originalPrice">
                                            <i class="icon-help-circle"></i>
                                            <span>{{ product.originalPrice }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="alert alert-warning">
                            Cart is empty
                        </div>
                    </template>
                </div>

                <div class="cart-min__add-services" v-if="services.length">
                    <button class="btn btn-primary btn-sm-md text-uppercase" @click="onOpenServicesPanel" :disabled="visibleServicesPanel">
                        Add more services
                    </button>
                </div>
            </div>

            <div class="cart-min__summary">
                <div class="cart-min__subtotal">
                    <div class="cart-min__summary-label">Subtotal:</div>
                    <div>
                        <strong>{{ subtotal }}</strong>
                    </div>
                </div>
                <div class="cart-min__rules" v-if="code || rules.length">
                    <div class="cart-min__rules-rule" v-for="rule in rules">
                        <div class="cart-min__rules-rule-name" v-html="rule.name"></div>
                    </div>
                    <div class="cart-min__rules-rule" v-if="code">
                        <div class="cart-min__rules-rule-name" v-html="code.name"></div>
                        <div class="cart-min__rules-rule-value" v-if="code.applies">{{code.value}}</div>
                        <div class="cart-min__rules-rule-value text-danger" v-tippy="{ placement: 'top' }" :content="code.message" v-else><i class="icon-info"></i> Your cart does not meet the coupon requirements!</div>
                    </div>
                </div>
                <div class="cart-min__total">
                    <div class="product-price product-price--block product-price--xl">
                        <div class="product-price__value">{{ total }}</div>
                        <div class="product-price__text">(VAT incl.)</div>
                    </div>
                </div>
            </div>

            <div class="cart-min__checkout" v-if="items.length">
                <a :href="this.$config.checkoutUrl" class="btn btn-primary btn-icon" @click="goToCheckout">
                    <span>Go to Checkout</span>
                    <i class="icon icon-chevron-right"></i>
                </a>
            </div>
        </div>

        <div class="cart-min__overlay" @click="onCartClose"></div>
        <div class="cart-min__overlay-services" @click="onCloseServicesPanel"></div>
    </div>
</template>

<script>
    import CartObserver from '../_helpers/cart-observer';
    import {displayError, handleAjaxError} from '../_helpers/error-handler';
    import {trackAddToCart, trackRemoveFromCart} from '../_helpers/analytics';

    export default {
        name: 'Cart',
        data() {
            return {
                items: [],
                loading: true,
                code: null,
                rules: [],
                subtotal: 0,
                total: 0,
                visibleServicesPanel: false,
            };
        },
        computed: {
            services() {
                return this.$config.services.map(service => {
                    const item = this.items.find(item => item.id === service.id);

                    service.inCart = !!item;
                    service.quantity = item ? item.quantity : 0;

                    return service;
                });
            }
        },
        methods: {
            goToCheckout(e) {
                if(window.location.href ===  e.currentTarget.href) {
                    e.preventDefault();
                    this.onCartClose();
                }
            },
            onCartClose() {
                this.toggleBodyClass('show-cart', 'removeClass');
            },
            onOpenServicesPanel() {
                this.visibleServicesPanel = true;
                this.toggleBodyClass('show-cart-services', 'addClass');
            },
            onCloseServicesPanel() {
                this.visibleServicesPanel = false;
                this.toggleBodyClass('show-cart-services', 'removeClass');
            },
            onProductChangeQuantity(product, event) {
                const newQuantity = event.target.value < 1 ? 1 : event.target.value;
                const quantityDifference = newQuantity - product.quantity;

                product.quantity = newQuantity;
                this.onProductUpdate(product, quantityDifference);
            },
            onProductUpdate(product, quantity) {
                this.loading = true;

                this.$http.post(this.$config.updateProductUrl, {product: product.id, quantity}).then(() => {
                    CartObserver.update();

                    if (quantity > 0) {
                        trackAddToCart({
                            id: product.id,
                            name: product.name,
                            price: product.priceRaw,
                            type: product.type,
                            quantity,
                        });
                    } else {
                        trackRemoveFromCart({
                            id: product.id,
                            name: product.name,
                            price: product.totalPriceRaw,
                            type: product.type,
                            quantity: Math.abs(quantity),
                        });
                    }
                }, () => {
                    this.loading = false;
                    displayError();
                })
            },
            onProductAdd(product, quantity) {
                this.loading = true;

                this.$http.post(this.$config.addProductUrl, {product: product.id, quantity}).then(() => {
                    CartObserver.update();

                    trackAddToCart({
                        id: product.id,
                        name: product.name,
                        price: product.priceRaw,
                        type: product.type,
                        quantity,
                    });
                }, () => {
                    this.loading = false;
                    displayError();
                });
            },
            onProductRemove(product, quantity) {
                this.loading = true;

                this.$http.post(this.$config.updateProductUrl, {product: product.id, quantity: quantity * -1}).then(() => {
                    CartObserver.update();

                    trackRemoveFromCart({
                        id: product.id,
                        name: product.name,
                        price: product.totalPriceRaw,
                        type: product.type,
                        quantity,
                    });
                }, () => {
                    this.loading = false;
                    displayError();
                })
            },
            setCartData(data) {
                for (const [key, value] of Object.entries(data)) {
                    this[key] = value;
                }

                this.loading = false;
            },
            toggleBodyClass(className, addRemoveClass) {
                const el = document.body;

                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            }
        },
        mounted() {
            this.$http.get(this.$config.getCartUrl).then(response => {
                this.setCartData(response.data);
                CartObserver.update(this, response.data);
                CartObserver.subscribe((data, initiator) => {
                    if (initiator !== this) {
                        this.setCartData(data);
                    }
                });
            }).catch(handleAjaxError);
        },
    }
</script>
