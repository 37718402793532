import Vue from 'vue';
import axios from '../_helpers/axios';
import Cart from './Cart.vue';
import '../_helpers/vue-tippy';

require('../main/scripts/components/polyfills');

Vue.prototype.$http = axios;

new Vue({
    render: h => h(Cart),
    beforeMount() {
        Vue.prototype.$config = JSON.parse(this.$el.dataset.config);
    },
}).$mount('#app-cart');
