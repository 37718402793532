var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-min__inside" },
    [
      _vm.loading ? [_vm._m(0)] : _vm._e(),
      _vm._v(" "),
      _vm.services.length
        ? _c(
            "div",
            { staticClass: "cart-min__services" },
            [
              _c(
                "div",
                { staticClass: "cart-min__header cart-min__header--service" },
                [
                  _c("div", { staticClass: "cart-min__header-title" }, [
                    _vm._v("Additional Services")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-one-icon",
                      on: {
                        click: function($event) {
                          return _vm.onCloseServicesPanel()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-x" })]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.services, function(service) {
                return _c("div", { staticClass: "cart-min__service" }, [
                  _c("div", { staticClass: "cart-min__service-inside" }, [
                    service.icon
                      ? _c("div", {
                          staticClass: "cart-min__service-icon",
                          domProps: {
                            innerHTML: _vm._s(
                              "<i data-icon='&#x" + service.icon + ";'></i>"
                            )
                          }
                        })
                      : service.image
                      ? _c("div", { staticClass: "cart-min__service-image" }, [
                          _c("img", {
                            attrs: { src: service.image, alt: service.name }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "cart-min__service-name" }, [
                        _vm._v(_vm._s(service.name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cart-min__service-price" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "product-price product-price--cart-min-service"
                          },
                          [
                            _c("div", { staticClass: "product-price__value" }, [
                              _vm._v(_vm._s(service.price))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "product-price__text" }, [
                              _vm._v("(VAT incl.)")
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cart-min__service-buttons" }, [
                      _c(
                        "div",
                        { staticClass: "buttons-group buttons-group--cart" },
                        [
                          service.teaser
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: { maxWidth: 220 },
                                      expression: "{ maxWidth : 220 }"
                                    }
                                  ],
                                  staticClass:
                                    "cart-min__service-buttons-info btn btn-lighten btn-one-icon btn-one-icon--md rounded-circle",
                                  attrs: { content: service.teaser }
                                },
                                [_c("i", { staticClass: "icon icon-info" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          service.inCart
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-one-icon btn-one-icon--md rounded-circle",
                                  on: {
                                    click: function($event) {
                                      return _vm.onProductRemove(
                                        service,
                                        service.quantity
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "icon icon-x" })]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-one-icon btn-one-icon--md rounded-circle",
                                  on: {
                                    click: function($event) {
                                      return _vm.onProductAdd(service, 1)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon icon-shopping-bag"
                                  })
                                ]
                              )
                        ]
                      )
                    ])
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "cart-min__content" }, [
        _c("div", { staticClass: "cart-min__header" }, [
          _c("div", { staticClass: "cart-min__header-title" }, [
            _vm._v("Your Cart")
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-one-icon",
              on: {
                click: function($event) {
                  return _vm.onCartClose()
                }
              }
            },
            [_c("i", { staticClass: "icon-x" })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cart-min__content-main" }, [
          _c(
            "div",
            { staticClass: "cart-min__products" },
            [
              _vm.items.length
                ? _vm._l(_vm.items, function(product) {
                    return _c("div", { staticClass: "cart-min__product" }, [
                      _c("div", { staticClass: "cart-min__product-row" }, [
                        _c("div", { staticClass: "cart-min__product-name" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(product.name) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cart-min__product-remove" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-no-style",
                              on: {
                                click: function($event) {
                                  return _vm.onProductRemove(
                                    product,
                                    product.quantity
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-x" })]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cart-min__product-row" },
                        [
                          _c("div", [_vm._v(_vm._s(product.unitPrice))]),
                          _vm._v(" "),
                          product.hasQuantity
                            ? [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "input-quantity" }, [
                                  _c("input", {
                                    attrs: { type: "number", min: "1" },
                                    domProps: { value: product.quantity },
                                    on: {
                                      change: function($event) {
                                        return _vm.onProductChangeQuantity(
                                          product,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      placement: "top",
                                      onShow: function() {
                                        return product.rules.length > 0
                                      }
                                    },
                                    expression:
                                      "{ placement: 'top', onShow: () => product.rules.length > 0}"
                                  }
                                ],
                                staticClass:
                                  "product-price product-price--block product-price--md",
                                attrs: { content: product.rules.join(", ") }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "product-price__value" },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(product.totalPrice) +
                                        "\n                                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                product.totalPrice !== product.originalPrice
                                  ? _c(
                                      "div",
                                      { staticClass: "product-price__old" },
                                      [
                                        _c("i", {
                                          staticClass: "icon-help-circle"
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(product.originalPrice))
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ],
                        2
                      )
                    ])
                  })
                : [
                    _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "\n                        Cart is empty\n                    "
                      )
                    ])
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _vm.services.length
            ? _c("div", { staticClass: "cart-min__add-services" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm-md text-uppercase",
                    attrs: { disabled: _vm.visibleServicesPanel },
                    on: { click: _vm.onOpenServicesPanel }
                  },
                  [
                    _vm._v(
                      "\n                    Add more services\n                "
                    )
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cart-min__summary" }, [
          _c("div", { staticClass: "cart-min__subtotal" }, [
            _c("div", { staticClass: "cart-min__summary-label" }, [
              _vm._v("Subtotal:")
            ]),
            _vm._v(" "),
            _c("div", [_c("strong", [_vm._v(_vm._s(_vm.subtotal))])])
          ]),
          _vm._v(" "),
          _vm.code || _vm.rules.length
            ? _c(
                "div",
                { staticClass: "cart-min__rules" },
                [
                  _vm._l(_vm.rules, function(rule) {
                    return _c("div", { staticClass: "cart-min__rules-rule" }, [
                      _c("div", {
                        staticClass: "cart-min__rules-rule-name",
                        domProps: { innerHTML: _vm._s(rule.name) }
                      })
                    ])
                  }),
                  _vm._v(" "),
                  _vm.code
                    ? _c("div", { staticClass: "cart-min__rules-rule" }, [
                        _c("div", {
                          staticClass: "cart-min__rules-rule-name",
                          domProps: { innerHTML: _vm._s(_vm.code.name) }
                        }),
                        _vm._v(" "),
                        _vm.code.applies
                          ? _c(
                              "div",
                              { staticClass: "cart-min__rules-rule-value" },
                              [_vm._v(_vm._s(_vm.code.value))]
                            )
                          : _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: { placement: "top" },
                                    expression: "{ placement: 'top' }"
                                  }
                                ],
                                staticClass:
                                  "cart-min__rules-rule-value text-danger",
                                attrs: { content: _vm.code.message }
                              },
                              [
                                _c("i", { staticClass: "icon-info" }),
                                _vm._v(
                                  " Your cart does not meet the coupon requirements!"
                                )
                              ]
                            )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cart-min__total" }, [
            _c(
              "div",
              {
                staticClass:
                  "product-price product-price--block product-price--xl"
              },
              [
                _c("div", { staticClass: "product-price__value" }, [
                  _vm._v(_vm._s(_vm.total))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-price__text" }, [
                  _vm._v("(VAT incl.)")
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.items.length
          ? _c("div", { staticClass: "cart-min__checkout" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-icon",
                  attrs: { href: this.$config.checkoutUrl },
                  on: { click: _vm.goToCheckout }
                },
                [
                  _c("span", [_vm._v("Go to Checkout")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "icon icon-chevron-right" })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "cart-min__overlay",
        on: { click: _vm.onCartClose }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "cart-min__overlay-services",
        on: { click: _vm.onCloseServicesPanel }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cart-min__loading" }, [
      _c("div", { staticClass: "spinner-border text-primary" }),
      _vm._v(" "),
      _c("p", [_vm._v("Cart Update")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "icon-x" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }