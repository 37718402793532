import Vue from 'vue';
import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy, {
    zIndex: 9999999,
    placement: 'left-start',
    maxWidth: 350,
    arrow: true,
    distance: 20,
    popperOptions: {
        modifiers: {
            hide: {
                enabled: false,
            },
        },
    },
});

Vue.component('tippy', TippyComponent);
