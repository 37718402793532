function isGoogleAnalyticsAvailable() {
    return window.cookiebar && window.gtag;
}

function getItemsFromCart(cart) {
    return cart.items.map(item => getItemFromCartItem(item));
}

function getItemFromCartItem(cartItem) {
    const item = {
        item_id: cartItem.id,
        item_name: cartItem.name,
        item_category: cartItem.type,
        price: cartItem.totalPriceRaw || cartItem.price,
    };

    if (cartItem.quantity) {
        item.quantity = cartItem.quantity;
    }

    return item;
}

export function getGoogleAnalyticsClientId() {
    return new Promise(resolve => {
        if (!isGoogleAnalyticsAvailable()) {
            resolve(null);
            return;
        }

        const googleAnalyticsCookie = Object.values(window.cookiebar.get().settings.cookies).find(cookie => cookie.type === 'googleAnalytics' && cookie.confirmed);

        if (googleAnalyticsCookie === undefined) {
            resolve(null);
            return;
        }

        const googleAnalyticsResourceUrl = googleAnalyticsCookie.resources.find(resource => resource.src.includes('https://www.googletagmanager.com/gtag/js'));

        if (googleAnalyticsResourceUrl === undefined) {
            resolve(null);
            return;
        }

        const googleAnalyticsChunks = /id\=(.*)$/.exec(googleAnalyticsResourceUrl.src);

        if (googleAnalyticsChunks === null) {
            resolve(null);
            return;
        }

        const googleAnalyticsId = googleAnalyticsChunks[1];

        if (!googleAnalyticsId) {
            resolve(null);
            return;
        }

        window.gtag('get', googleAnalyticsId, 'client_id', resolve);
    });
}

export function googleAnalyticsTrackAddToCart(cartItem) {
    if (!isGoogleAnalyticsAvailable()) {
        return;
    }

    window.gtag('event', 'add_to_cart', {
        currency: 'EUR',
        value: cartItem.price,
        items: [getItemFromCartItem(cartItem)],
    });
}

export function googleAnalyticsTrackViewCart(cart) {
    if (!isGoogleAnalyticsAvailable()) {
        return;
    }

    window.gtag('event', 'view_cart', {
        currency: 'EUR',
        value: parseFloat(cart.totalRaw),
        items: getItemsFromCart(cart),
    });
}

export function googleAnalyticsTrackRemoveFromCart(cartItem) {
    if (!isGoogleAnalyticsAvailable()) {
        return;
    }

    window.gtag('event', 'remove_from_cart', {
        currency: 'EUR',
        value: cartItem.price,
        items: [getItemFromCartItem(cartItem)],
    });
}

export function googleAnalyticsTrackBeginCheckout(cart) {
    if (!isGoogleAnalyticsAvailable()) {
        return;
    }

    window.gtag('event', 'begin_checkout', {
        currency: 'EUR',
        value: parseFloat(cart.total_raw),
        coupon: cart.code,
        items: getItemsFromCart(cart),
    });
}

export function googleAnalyticsTrackViewItem(cartItem) {
    if (!isGoogleAnalyticsAvailable()) {
        return;
    }

    window.gtag('event', 'view_item', {
        currency: 'EUR',
        value: cartItem.price,
        items: [getItemFromCartItem(cartItem)],
    });
}
